<template>
	<lba-section
		:parentComponentId="currentComponentId"
		:title="$t('permissions')"
		:slotType="'form'"
		expanded
		:key="objKey"
	>
		<template v-slot:controls v-if="!readOnly && loaded">
			<span>
				<i
					class="icon-plus-circle"
					:data-cy="`${currentComponentId}__addGroup`"
					@click.stop="addGroup()"
					v-tooltip="$t('settings.addGroup')"
				>
				</i>
			</span>
		</template>

		<template v-if="objectPermissions.length > 0">
			<div
				class="formRow"
				v-for="(perm, index) in objectPermissions"
				:key="index"
			>
				<s class="size-2">
					<small>{{ $t('settings.group') }}</small>
					<ValidationProvider
						ref="provider"
						:name="$t('settings.group')"
						:rules="{ requiredGroup: { data: perm, paths: ['profile_uid'], labels: [$t('settings.profile')]} }"
						:data-cy="`${currentComponentId}__slot`"
						v-slot="{ invalid, errors }"
					>
						<select
							:data-cy="`${currentComponentId}__object${index}__group__select`"
							:name="`group-${perm.role_uid}`"
							@change="change"
							v-model="perm.role_uid"
							:class="{
								'lba-invalid': dirty && invalid,
							}"
						>
							<option :value="null" :data-cy="`${currentComponentId}__object${index}__group__select__optionNull`">
								-- {{ $t('all') }} --
							</option>
							<option
								v-for="(group, index) in groupRoles"
								:value="group.role_uid"
								:key="group.role_uid"
								:data-cy="`${currentComponentId}__object${index}__group__select__option${index}`"
							>
								{{ group.role_name }}
							</option>
						</select>
						<template v-if="dirty">
							<span v-for="(err, index) in errors" :key="index" class="lba-messages">
								{{ err }}
							</span>
						</template>
					</ValidationProvider>
				</s>
				<s class="size-2">
					<small>{{ $t('settings.profile') }}</small>
					<ValidationProvider
						ref="provider"
						:name="$t('settings.profile')"
						:rules="{ requiredGroup: { data: perm, paths: ['role_uid'], labels: [$t('settings.group')] } }"
						:data-cy="`${currentComponentId}__slot`"
						v-slot="{ invalid, errors }"
					>
						<select
							:data-cy="`${currentComponentId}__object${index}__profile__select`"
							:name="`profile-${perm.profile_uid}`"
							@change="change"
							v-model="perm.profile_uid"
							:class="{
								'lba-invalid': dirty && invalid,
							}"
						>
							<option :value="null" :data-cy="`${currentComponentId}__object${index}__profile__select__optionNull`">
								-- {{ $t('all') }} --
							</option>
							<option
								v-for="(profile, index) in profiles"
								:value="profile.profile_uid"
								:key="profile.profile_uid"
								:data-cy="`${currentComponentId}__object${index}__profile__select__option${index}`"
							>
								{{ profile.profile_name }}
							</option>
						</select>
						<template v-if="dirty">
							<span v-for="(err, index) in errors" :key="index" class="lba-messages">
								{{ err }}
							</span>
						</template>
					</ValidationProvider>
				</s>
				<div class="form-controls">
					<i
						class="icon-delete"
						:data-cy="`${currentComponentId}__object${index}__remove`"
						v-on:click="removePermission(index)"
						v-tooltip="$t('abook.global.removeContact')"
					>
					</i>
				</div>
			</div>
		</template>
		<template v-else>
			{{ $t('noEntries') }}
		</template>
	</lba-section>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
import PermissionsModel from '../models/Permissions';

export default {
	mixins: [ComponentIdentifier],
	model: 'permissions',
	props: {
		objectUid: {
			type: String,
		},
		data: Object,
		objKey: [String, Number],
		dirty: Boolean,
		readOnly: Boolean,
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			permissionsModel: null,
			moduleList: [],
			objectPermissions: [],
			groupRoles: [],
			profiles: [],
			addedGroup: null,
			loaded: false,
		};
	},
	async created() {
		this.permissionsModel = new PermissionsModel(this.$http);
		await this.fetch();
	},
	methods: {
		async fetch() {
			const modules = await this.permissionsModel.getModules();
			this.moduleList = modules.data;

			const groupRoles = await this.permissionsModel.getGroupRoles();
			this.groupRoles = groupRoles.data;

			const profiles = await this.permissionsModel.getProfiles();
			this.profiles = profiles.data;

			if (!this.isNew) {
				if ($_.isEmpty(this.objectUid)) {
					console.error('[LbaObjectHasPermissions] (fetch) Missing objectUid');
					return;
				} else {
					const objectPermissions = await this.permissionsModel.getObjectHasPermissions(this.objectUid);
					this.objectPermissions = objectPermissions.data;
				}
			}
			this.loaded = true;
		},
		async change(force) {
			if (this.data && (force || !$_.isEqual(this.objectPermissions, this.data.objectPermissions))) {
				this.data.objectPermissions = $_.filter(this.objectPermissions, (perm) => perm.role_uid || perm.profile_uid);
			}
			this.$emit('change');

			await this.$nextTick();
			$_.forEach(this.$refs.provider, (provider) => {
				if (!$_.isEmpty(provider)) {
					provider.validate();
				}
			});
		},
		addGroup() {
			this.objectPermissions.push({
				acl_object_uid: this.objectUid,
				role_uid: null,
				profile_uid: null,
			});
			this.change(true);
		},
		removePermission(index) {
			this.objectPermissions.splice(index, 1);
			this.change(true);
		},
	},
};
</script>
