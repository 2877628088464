<template>
	<component
		v-if="noInput && $_.isEmpty(options.format)"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
	>
		{{ $d(new Date(data[key]), 'long') }}
	</component>
	<component
		v-else-if="noInput && ['time_since', 'from_now'].includes(options.format)"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
		v-tooltip="(data[key] && $d(new Date(data[key]), 'long')) || ''"
	>
		{{ $timeBeforeAfter(data[key]) }}
	</component>
	<component
		v-else-if="noInput"
		:is="rootTag"
		:class="classes"
		:data-cy="currentComponentId"
		v-tooltip="(data[key] && $d(new Date(data[key]), options.format)) || ''"
	>
		{{ $d(new Date(data[key]), options.format) }}
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		:data-cy="currentComponentId"
	>
		<small>
			{{ label }}
			<span v-if="isRequired" class="required-sign"></span>
			<i v-if="titleTooltip" class="icon-tooltip" v-tooltip="titleTooltip"></i>
		</small>
		<fake-provider
			:name="$getLocale(label)"
			:vid="`${$getLocale(label)}-${inputRef}`"
			:rules="rules"
			v-slot="{ invalid, errors }"
			immediate
			:data-required-recommended="requiredRecommended"
		>
			<lba-datepicker
				:parentComponentId="currentComponentId"
				v-model="data[key]"
				v-bind="bindOptions()"
				:mode="'date'"
				:readOnly="readOnly"
				:yearRange="options.yearRange || 10"
				:class="{
					'lba-invalid': (invalid && dirty),
					'showWarning': (showRecommendation)
				}"
				@input="onEvent('input')"
				@change="onEvent('change')"
			></lba-datepicker>
			<template v-if="dirty">
				<span v-for="(err, index) in errors" :key="index" class="lba-messages" :data-cy="`${currentComponentId}__error${index}`">
					{{ err }}
				</span>
				<span v-for="(msg, index) in customMessages"
					:key="index" class="lba-messages warning"
					:data-cy="`${currentComponentId}__error${index}`"
				>
					{{ msg }}
				</span>
			</template>
		</fake-provider>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputDate',
	mixins: [CustomInputMixin],
	props: {
		attribute: Object,
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
	},
	created() {
		this.prepareComponent();
	},
};
</script>
