<template>
	<span ref="app">
		<span id="alertContainer" class="ng-cloak"></span>
		<router-view v-if="$route.fullPath.startsWith('/login')"></router-view>
		<template v-else-if="
			$user.user_name != null &&
			(
				!$user.is_anonymous_user ||
				($user.is_anonymous_user && $root.anonymousAccess)
			)
		">
			<div id="header" :class="{ fixed: isHeaderTopFixed }">
				<div id="headerTop">
					<div id="menuButton" class="userSelectNone" :class="{ close: overlay }" @click="toggleOverlay"><i></i>
					</div>
					<h1 id="logo" :class="{ 'logo-big': !isMenuSmall, 'logo-small': isMenuSmall }">
						<a @click="$root.$emit('tabs.open-home')">
							<div v-if="!isMenuSmall">
								<span id="logoBig">
									<span id="logoPrefix">{{ appTitlePrefix }}</span>
									<span id="logoSuffix">{{ appTitleSuffix }}</span>
								</span>
							</div>
							<div v-else>
								<span id="logoSmall"><i class="icon-logo"></i></span>
							</div>
						</a>
					</h1>
				</div>
				<!-- TABY -->
				<div id="headerTabs">
					<lba-tabs
						v-if="userLoaded"
						:keepAlive="$refs.keepAlive"
						:menuModules="menuModules"
						@reloading-change="onTabsReloadingChange"
					/>
				</div>
				<div id="headerRight">
					<div id="headerDisconected" :title="$t('offlineInfo')" :class="{ active: !socketConnected }">
						<i class="icon-alert top-2"></i>&nbsp;
						<span>{{ $t('offline') }}</span>
					</div>
					<div
						id="headerDisconected"
						:title="$t('outOfDateInfo')"
						:class="{ active: !$versions.isActual || modulesChanged }"
						@click="reload()"
					>
						<i class="icon-alert top-2"></i>&nbsp;
						<span>{{ $t('outOfDate') }}</span>
					</div>
					<div
						id="headerDisconected"
						class="warn"
						:title="$t('su.suInfo', { user: $user.user_name})"
						:class="{ active: $user.original_user }"
					>
						<i class="icon-alert top-2"></i>&nbsp;
						<span>{{ $t('su.su') }}</span>
					</div>
					<div id="headerPlugins" v-if="$headerPlugins.length && $user.user_name && !$user.is_anonymous_user">
						<template v-for="(headerPlugin) in $headerPlugins">
							<component :key="headerPlugin.id" :is="headerPlugin.component" parentComponentId="app"></component>
						</template>
					</div>
					<div id="user">
						<span
							v-lba-expander.closeOnClick.closeOnItemClick
							data-cy="app__expandUserMenu"
							v-tooltip="$t('userLogin') + $user.user_name"
						>
							<span id="userPhoto">
								<i class="icon-user"></i>
							</span>
						</span>
						<div id="userMenu" class="headerMenu expand">
							<span id="userPhotoBig">
								<i class="icon-user"></i>
							</span>
							<h4>
								{{ $user.getName() }}
							</h4>
							<a class="headerMenuItem" @click="openSettings()" data-close data-cy="app__expandUserMenu__openSettings">
								<i class="icon16 icon-settings"></i>
								<span>
									{{ $t('settings.settings') }}
								</span>
							</a>
							<router-link to="/login" v-show="$user.is_anonymous_user" class="headerMenuItem"
								data-cy="app__expandUserMenu__login"
							>
								<i class="icon16 icon-login"></i>
								<span>
									{{ $t('log_in') }}
								</span>
							</router-link>
							<a v-show="$user.allow_su" @click="openSuMode()"
								class="headerMenuItem"
							>
								<i class="icon16 icon-users"></i>
								<span>
									{{ $t('su.switchUser') }}
								</span>
							</a>
							<a v-show="$user.original_user" @click="cancelSuMode()" class="headerMenuItem"
								data-cy="app__expandUserMenu__openDialogSuResetUser"
							>
								<i class="icon16 icon-user"></i>
								<span>
									{{ $t('su.cancel') }}
								</span>
							</a>
							<a class="headerMenuItem" @click="openChangelog()" data-close data-cy="app__expandUserMenu__openChangelog">
								<i class="icon16 icon-history"></i>
								<span>
									{{ $t('releaseNotes') }}
								</span>
							</a>
							<a
								v-if="isDocumentationAvailable"
								class="headerMenuItem"
								@click="openDocumentation()"
								data-close
								data-cy="app__expandUserMenu__openDocumentation"
							>
								<i class="icon16 icon-mailboxes-whitelist"></i>
								<span>
										{{ $t('documentation') }}
								</span>
							</a>
							<a class="headerMenuItem" @click="openAbout()" data-cy="app__expandUserMenu__openDialogAbout">
								<i class="icon-logo"></i>
								<span>
									{{ $t('settings.about') }}
								</span>
							</a>
							<a
								v-show="!$user.is_anonymous_user"
								@click="logout"
								class="headerMenuItem"
								data-cy="app__expandUserMenu__logout"
							>
								<i class="icon16 icon-logout"></i>
								<span>
									{{ $t('log_out') }}
								</span>
							</a>
						</div>
						<!--
							pokud se ma loader zobrazovat, tak se mu musi pridat class .active
							classy .loadingFile a .loadingApp meni barvu loaderu. muzou byt pouzity obe najednou
						-->
						<!--TODO
						<span id="loader" :class="{
								active: app.$loadingIndicator.loading || app.$upload.active,
								loadingApp: app.$loadingIndicator.loading,
								loadingFile: app.$upload.active
							}"
						>
							<svg width="14px" height="14px" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
								TODO<circle
									ng-attr-stroke_dashoffset="{{ app.uploadProgress() }}"
									stroke-width="2" cx="7" cy="7" r="5" fill="none" stroke-linecap="round"></circle>
							</svg>
						</span>-->
					</div>
				</div>
			</div>
			<div id="page" v-lba-loading class="" v-if="!$route.fullPath.startsWith('/login') && !$root.loadingLogin">
				<lba-menu
					v-if="userLoaded"
					v-model="menuModules"
					:overlay="overlay"
					@toggle-overlay="toggleOverlay"
					@size-state="isMenuSmall = $event"
					:class="{ fixed: isHeaderTopFixed }"
				/>
				<div id="menuOverlay" @click="toggleOverlay"></div>
				<div v-if="areTabsReady" id="main" tabindex="0" :class="{ menuOverlay: overlay }">
					<div class="page-info">
						<lba-breadcrumb v-if="userLoaded" main></lba-breadcrumb>
						<lba-history-bar parentComponentId="app"></lba-history-bar>
						<lba-favourite-page v-if="showFavouritePage()" />
						<button v-if="showTabCloseButton()"
							data-cy="app__closeCurrentTab"
							class="button-cancel"
							v-tooltip="$t('close')"
							@click.stop="$root.$emit('close-current-tab')">
						</button>
					</div>
					<div v-if="getAnyError()" class="page-content" data-cy="app__viewErrorWrap">
						<div class="error-wrap">
							<h1 v-if="getErrorType() === 'notFound'" data-cy="app__viewNotFound">
								{{ (getError() ? $t(getError()) : $t('viewNotFound')) }}
							</h1>
							<h1 v-else-if="getErrorType() === 'permissionDenied'" data-cy="app__viewPermissionDenied">
								{{ (getError() ? $t(getError()) : $t('viewPermissionDenied')) }}
							</h1>
							<h1 v-else-if="getErrorType() === 'serverError'" data-cy="app__viewServerError">
								{{ (getError() ? $t(getError()) : $t('viewServerError')) }}
							</h1>
							<h1 v-else-if="getErrorType() === 'recordDeleted'" data-cy="app__viewRecordDeleted">
								{{ (getError() ? $t(getError()) : $t('viewRecordDeleted')) }}
							</h1>
							<h1 v-else-if="getErrorType() === 'missingParameter'" data-cy="app__viewMissingParameter">
								{{ $t('viewMissingParameter', { params: getError() }) }}
							</h1>
							<h1 v-else-if="getErrorType() === 'wrongParameter'" data-cy="app__viewWrongParameter">
								{{ $t('viewWrongParameter', { params: getError() }) }}
							</h1>

							<button
								v-if="showReloadButton()"
								type="button"
								@click="reloadPath"
							>
								{{ $t('reload') }}
							</button>
						</div>
					</div>
					<lba-keep-alive v-if="userLoaded" ref="keepAlive">
						<router-view
							v-if="!$route.fullPath.startsWith('/login') && !$root.loadingLogin"
							v-show="!getAnyError()"
							:key="getKey()"
							@view-not-found="onError($event, 'notFound')"
							@view-permission-denied="onError($event, 'permissionDenied')"
							@view-server-error="onError($event, 'serverError')"
							@view-record-deleted="onError($event, 'recordDeleted')"
							@view-missing-parameter="onError($event, 'missingParameter')"
							@view-wrong-parameter="onError($event, 'wrongParameter')"
						/>
					</lba-keep-alive>
				</div>
				<!--lba-parse-attr-fix="i18n"-->
			</div>
			<!-- FORCE CHANGE PASSWORD  -->
			<lba-dialog
				parentComponentId="app"
				componentId="forcePasswordChange"
				name="force_password_change"
				ref="force_password_change"
				:title="$t('settings.user.passwordChange')"
				@mounted="initPasswordChangeDialog()"
				:showClose="false"
				modal
			>
				<template #default="props">
					<div class="popup-body">
						<ValidationObserver ref="formForcedPassChange">
							<form name="formForcedPassChange">
								<div class="form" style="max-width: 544px; padding: 40px 0;">
									<s>
										<small>{{ $t('settings.user.currentPassword') }}</small>
										<!-- <input v-model="$user.current_password" type="password" name="oldPass"> -->
										<lba-input-password v-model="current_password" name="oldPass" :readable="false" writeable
											:parentComponentId="props.parentComponentId" componentId="oldPassword"
											autocomplete="new-password"
										></lba-input-password>
									</s>
									<ValidationProvider
										:name="$t('settings.user.newPassword')"
										v-slot="{ invalid, errors }"
										:rules="{
											regex: passwordRegex,
											notContains: (passwordRules && passwordRules.easy_to_guess) ? avoidedItems : []
										}"
									>
										<s>
											<small>{{ $t('settings.user.newPassword') }}</small>
											<lba-input-password
												v-model="password"
												name="newPass"
												:readable="false"
												writeable
												:parentComponentId="props.parentComponentId"
												componentId="newPassword"
												:class="{ 'lba-invalid': invalid }"
												@input="testingPassword = password;"
												:rules="passwordRules"
												autocomplete="new-password"
											></lba-input-password>
											<template v-if="invalid">
												<!-- ERRORS FROM PASSWORD MIXIN - PASSWORD RULES -->
												<span
													v-for="(err, index) in passwordErrors"
													:key="`pwe-${index}`"
													:data-cy="`${props.parentComponentId}__password__error${errors.length + index}`"
													class="lba-messages"
												>{{ err }}</span>
												<span
													v-for="(err, index) in errors"
													:key="index"
													:data-cy="`${props.parentComponentId}__password__error${index}`"
													class="lba-messages"
												>{{ err }}</span>
											</template>
										</s>
									</ValidationProvider>
								</div>
							</form>
						</ValidationObserver>
					</div>
					<div class="popup-footer">
						<button type="button" class="buttonBig" @click="onForcedPassChange" :disabled="!current_password || !password"
							:data-cy="`${props.parentComponentId}__changePassword`"
						>
							{{ $t('changePassword') }}
						</button>
						<button type="button" class="buttonBig buttonRed" @click="logout" :data-cy="`${props.parentComponentId}__logout`">
							{{ $t('logout') }}
						</button>
					</div>
				</template>
			</lba-dialog>
			<!-- FORCE CHANGE 2FA  -->
			<tfa-settings
				name="force_2fa_change"
				ref="force_2fa_change"
				parentComponentId="app"
				componentId="force_2fa_settings"
				@logout="logout"
				:logout="true"
			/>
			<!-- PEACEFULL CHANGE 2FA  -->
			<tfa-settings
				name="2fa_change"
				ref="2fa_change"
				parentComponentId="app"
				componentId="2fa_settings"
			/>
			<!-- IDLE INFO -->
			<lba-dialog-modal name="idle_dialog" parentComponentId="app" componentId="idle">
				<div class="popup-header" style="height: 40px;">
				</div>
				<div class="popup-body">
					<div class="delay">
						<div class="time-spinner"></div>
					</div>
					<div
						class="mb-5"
					>{{ $t('settings.user.idleDialog') }} {{ $d(new Date(this.idleCountdownTime), 'timeDiff') }} {{ $t('minutes') }}</div>
				</div>
			</lba-dialog-modal>
			<lba-dialog-modal name="suElsewhere_dialog" parentComponentId="app" componentId="suElsewhere" modal withoutClose>
				<template #default="props">
					<div class="popup-header">
						<h2>{{ $t('su.windowBlocked') }}</h2>
					</div>
					<div class="popup-body" style="max-width: 500px;">
						{{ $t('su.userChanged') }}
					</div>
					<div class="popup-footer">
						<button type="button" class="buttonBig" @click="reload"
							:data-cy="`${props.parentComponentId}__reload`"
						>
							{{ $t('su.reload') }}
						</button>
					</div>
				</template>
			</lba-dialog-modal>
			<lba-dialog-modal name="about" class="about" :title="$t('settings.about')" parentComponentId="app" componentId="about">
				<template #default="props">
					<div class="popup-body">
						<h2
							style="font-size: 2rem; margin-bottom: 10px; margin-top: 0"
						>{{ appTitlePrefix }}<span class="blue">{{ appTitleSuffix }}</span></h2>
						<span style="display: inline-block;">{{ $t('settings.version') + ` : ${about.version}` }}</span>
						<span
							v-if="isClipboardAvailable()"
							@click.stop="copyModulesVersions"
							class="hover-pointer"
							style="margin-left: 10px;"
							v-tooltip="$t('settings.copyVersionsToClipboard')"
						>
							<i class="icon-clone"></i>
						</span>
						<p class="note mb-2 mt-3">{{ $t('settings.modules') }}:</p>
						<p
							v-for="(mod, index) in about.modules"
							:key="`about-module-${index}`"
						>
							<strong>
								{{ `${$t(`${mod.module}.menu.${mod.module}`)}  -  ${mod.version}` }}
							</strong>
						</p>
						<p class="note mt-4">
							Copyright © {{ new Date().getFullYear() }} LinuxBox.cz, s.r.o., {{ $t('settings.copyright') }}<br><br>
							<a href="https://www.linuxbox.cz/" target="_blank">www.linuxbox.cz</a>
						</p>
					</div>
					<div class="popup-footer">
						<button type="button" class="buttonBig" v-lba-dialog-close="'about'"
							:data-cy="`${props.parentComponentId}__closeDialog`"
						>
							{{ $t('ok') }}
						</button>
					</div>
				</template>
			</lba-dialog-modal>
			<!-- SU user -->
			<lba-dialog-modal
				parentComponentId="app"
				componentId="suMode"
				name="suMode"
				:title="$t('su.switchUser')"
				:warningOnEscape="switchUserDirty"
			>
				<template #default="props">
					<div class="popup-body">
						<p style="max-width: 500px">
							{{ $t('su.switchWarning') }}
						</p>
						<br>
						<s>
							<small>{{ $t('settings.user.user') }}</small>
							<select
								name="suUser"
								v-model="suUser"
								:data-cy="`${props.parentComponentId}__suUser__select`"
								@change="switchUserDirty = true"
							>
								<option
									v-for="(user, index) in suUsers"
									:key="`suUser-${user.user_name}`"
									:value="user.user_name"
									:data-cy="`${props.parentComponentId}__suUser__select__option${index}`"
								>
									{{ user.user_name }}
								</option>
							</select>
						</s>
					</div>
					<div class="popup-footer">
						<button type="button" class="button" @click="setSuMode()"
							:data-cy="`${props.parentComponentId}__confirm_su_user__button`"
						>
							{{ $t('ok') }}
						</button>
						<button type="button" class="button buttonInverse" @click="closeSuMode()"
							:data-cy="`${props.parentComponentId}__decline_su_user__button`"
						>
							{{ $t('cancel') }}
						</button>
					</div>
				</template>
			</lba-dialog-modal>
		</template>
		<portal-target
			id="rootPortalTarget"
			name="root"
			multiple
		></portal-target>
	</span>
</template>

<style>
/* .page-info {
	@change="portalTargetChange"
	height: 32px;
} */
</style>

<script>
import UserModel from './models/User';
import ColorThemes from './mixins/ColorThemes';
import TabErrors from './mixins/TabErrors';
import PasswordMixin from './mixins/Password';
import CheckDocumentation from './mixins/CheckDocumentation';
import TfaSettings from './components/TfaSettings.vue';
import AppTitle from './mixins/AppTitle';

export default {
	name: 'App',
	data() {
		return {
			isMenuSmall: null,
			state: false,
			timezoneList: [],
			overlay: false,
			userLoaded: false,
			serverLoaded: false,
			idleCountdownInterval: null,
			idleCountdownTime: 0,
			idleStartTime: null,
			menuModules: null,
			tabs: null,
			rootPortalTarget: null,
			observer: null,
			closeListener: null,
			dirtyUids: [],
			tabsReloading: false,
			currentPath: this.$route.path,
			userModel: null,
			areTabsReady: false,
			current_password: null,
			password: null,
			socketConnected: true,
			about: [],
			theme: 'theme-basic',
			themes: null,
			modulesChanged: false,
			avoidedItems: [],
			avoidedItemsWhole: {},
			suSocket: null,
			suUser: null,
			suUsers: [],
			suElsewhere: false,
			switchUserDirty: false,
			lastTouchY: null,
			lastScrollDirection: null,
			isHeaderTopFixed: false,
		};
	},
	components: { TfaSettings },
	mixins: [ColorThemes, TabErrors, PasswordMixin, CheckDocumentation, AppTitle],
	watch: {
		$route() {
			if (!this._inactive) {
				const { route } = this.$router.resolve({ name: this.$route.matched?.[0]?.name, params: this.$route.params });

				if (this.currentPath.startsWith('/login') && !route.path.startsWith('/login')) {
					this.reloadWhenPermissionDenied();
				}
				this.currentPath = route.path;
			}
		},
		theme(newValue) {
			$_.forEach(this.themes, (th) => {
				document.body.classList.remove(th.value);
			});
			document.body.classList.add(newValue);
			this.$root.$emit('theme-changed');
		},
		suElsewhere(newValue) {
			if (newValue === true) {
				this.$root.$emit('dialog-open', { name: 'suElsewhere_dialog' });
			} else {
				this.$root.$emit('dialog-close', 'suElsewhere_dialog');
			}
		},
	},
	async created() {
		this.$root.app = this;
		this.$user.is_dark_theme = this.isDarkTheme(this.theme);
		document.body.classList.add(this.theme);
		if (typeof LBTray === 'object') {
			document.body.classList.add('tray');
		}
		// function to prevent closing tab
		this.closeListener = function (e) {
			e.preventDefault();
			e.returnValue = '';
		};

		this.$root.$once('user-loaded', () => {
			this.userLoaded = true;
			this.init();
		});
		this.$root.$once('servers-loaded', this.onServerLoaded);
		this.$root.$listen('tabs.ready', this.tabsReady, this);
		this.$root.$listen('socket.state-changed', (state) => { this.socketConnected = state; }, this);
		this.$root.$listen('user-loaded', this.userUpdated, this);
		this.$root.$listen('socket-opened', this.userUpdated, this);
		this.$root.$listen('user-idle', this.onIdle, this);
		this.$root.$listen('user-active', this.onActive, this);
		this.$root.$listen('go-fullscreen', this.onGoFullscreen, this);
		this.$root.$listen('exit-fullscreen', this.onExitFullscreen, this);
		this.$root.$listen('prevent-closing', this.preventClosing, this);
		this.$root.$listen('allow-closing', this.allowClosing, this);
		this.$root.$listen('allow-closing-uids', this.allowClosingUids, this);
		this.$root.$listen('permissions.modules-changed', () => { this.modulesChanged = true; }, this);
		this.$root.$listen('dialog-open', this.onDialogOpen, this);
		this.$root.$listen('permissions-reload', this.permissionsReload, this, true);
		this.$root.$listen('user-modules-loaded', this.setHomePageRoutes, this, true);
		this.permissionsReload();

		document.addEventListener('fullscreenchange', this.onFullscreenChange);
		document.addEventListener('mozfullscreenchange', this.onFullscreenChange);
		document.addEventListener('MSFullscreenChange', this.onFullscreenChange);
		document.addEventListener('webkitfullscreenchange', this.onFullscreenChange);

		this.$user.queryAction('timezoneList').then((data) => {
			this.timezoneList = data;
		}).catch((err) => {
			console.error('Timezone list load error:', err);
		});

	},
	mounted() {
		this.$notify.setContainer();
		this.rootPortalTarget = document.getElementById('rootPortalTarget');

		this.observer = new MutationObserver(this.rootPortalTargetMutation);
		this.observer.observe(this.rootPortalTarget, { childList: true });

		this.userModel = new UserModel(this.$http);

		window.addEventListener('wheel', this.onWheel);
		window.addEventListener('touchstart', this.onTouchStart);
		window.addEventListener('touchmove', this.onTouchMove);
	},
	async beforeDestroy() {
		if (this.suSocket) {
			await this.suSocket.unsubscribe();
			this.suSocket = null;
		}

		window.removeEventListener('wheel', this.onWheel);
		window.removeEventListener('touchstart', this.onTouchStart);
		window.removeEventListener('touchmove', this.onTouchMove);
	},
	methods: {
		onWheel(event) {
			if (event.deltaY > 0) {
				if (this.lastScrollDirection === 'up') {
					this.isHeaderTopFixed = false;
				}
				this.lastScrollDirection = 'down';

			} else if (event.deltaY < 0) {
				if (this.lastScrollDirection === 'down') {
					this.isHeaderTopFixed = true;
				}
				this.lastScrollDirection = 'up';

			}
		},
		onTouchStart(event) {
			if (!isNaN(event?.touches?.[0]?.clientY)) {
				this.lastTouchY = event.touches[0].clientY;
			}
		},
		onTouchMove(event) {
			if (isNaN(event?.touches?.[0]?.clientY)) {
				return;
			}

			const currentTouchY = event.touches[0].clientY;

			if (currentTouchY < this.lastTouchY) {
				if (this.lastScrollDirection === 'up') {
					this.isHeaderTopFixed = false;
				}
				this.lastScrollDirection = 'down';

			} else if (currentTouchY > this.lastTouchY) {
				if (this.lastScrollDirection === 'down') {
					this.isHeaderTopFixed = true;
				}
				this.lastScrollDirection = 'up';

			}
		},
		permissionsReload() {
			if ($_.isEmpty(this.$user) || $_.isEmpty(this.$user.extraSettings)) {
				return;
			}

			let anyChange = false;
			$_.forEach(this.$user.extraSettings, (setting) => {
				let newPermsDisabled = false;
				if (!$_.isEmpty(setting.perms)) {
					const perms = [];

					$_.forEach(setting.perms, (el) => {
						perms.push(this.$permissions.checkPermission(el));
					});

					if (setting.permsEval && setting.permsEval === 'AND') {
						newPermsDisabled = (perms.indexOf(false) >= 0);
					} else {
						newPermsDisabled = !$_.some(perms, Boolean);
					}
				}

				if (newPermsDisabled !== (!!setting.permsDisabled)) {
					anyChange = true;
					setting.permsDisabled = newPermsDisabled;
				}
			});
			if (anyChange) {
				this.extraSettingsKey += 1;
				this.$emit('app.extra-settings-changed');
			}
		},
		showFavouritePage() {
			const { resolved } = this.$router.resolve(this.$root.homeRoute);
			return (
				this.userLoaded &&
				this.$user.loadedModules &&
				this.$user.loadedModules.includes('dashboard') &&
				this.$route.path !== resolved.path
			);
		},
		showTabCloseButton() {
			return !this.$route.meta.hideCloseButton && this.tabs && this.tabs.showCloseButton(this.tabs.activeTab);
		},
		onTabsReloadingChange(state) {
			this.tabsReloading = state;
		},
		rootPortalTargetMutation(mutations) {
			if (mutations.find((mutation) => mutation.addedNodes.length > 0)) {
				const IDs = Array.prototype.map.call(this.rootPortalTarget.children, (child) => child.id);
				this.$root.$emit('portal-target.change', IDs);
			}
		},
		tabsReady() {
			this.tabs = this.$root.$tabs;
			this.areTabsReady = true;
		},
		getKey(route = this.$route) {
			let path = null;

			if (route.matched.length < 2) {
				path = route.path;
			} else {
				const matched = route.matched[0];
				const resolved = this.$router.resolve({ name: matched.name, params: route.params });
				path = resolved.route.path;
			}

			if (this.tabsReloading) {
				const uid = this.$generateUID();
				path += uid;
				setTimeout(async () => {
					await this.$nextTick();
					this.$root.$emit('keep-alive.remove-cache', path);
					await this.$nextTick();
					this.tabsReloading = false;
				}, 0);
			}
			return path;
		},
		init() {
			if (!this.userLoaded || !this.serverLoaded) return;

			// su socket start
			if (!this.suSocket && (this.$user.allow_su || this.$user.original_user)) {
				const attrs = {
					uid: this.$user.role_uid,
					original_user: this.$user.original_user,
					sessionID: this.serverSettings.sessionID,
				};
				this.suSocket = this.$socket.on('/su/switched', attrs, (message) => {
					console.log('su socket message: ', message);
					if (message.uid !== null) {
						this.suElsewhere = true;
					} else {
						this.suElsewhere = false;
					}
				});
			}
		},
		onServerLoaded() {
			this.refreshPasswordServerSettings();
			this.checkServers();
			this.initForceChangeDialog();
			const serverSettings = $_.first(this.$root.servers);
			this.serverLoaded = true;

			// nastaveni favicony podle tematu (favicony musi byt vsechny ve slozce public/images/ a musi mit jmeno favicon-jmenoTematu.png)
			const theme = this.$root.servers[0].theme || 'linuxbox';
			document.querySelector(`link[rel*='icon']`).href = `/images/favicon-${theme}.png`;

			this.setAppTitle(false);
			this.$root.$emit('tabs.route-meta-update');

			if (!$_.isEmpty(serverSettings.default_homepage)) {
				this.$root.defaultHomePage = serverSettings.default_homepage;
			}
			if (!$_.isEmpty(serverSettings.default_homepage_route)) {
				this.$root.defaultHomePageRoute = serverSettings.default_homepage_route;
			}

			if (this.$user.loadedModules) {
				this.setHomePageRoutes();
			}
			this.$i18n.locale = this.$user?.lang || serverSettings?.lang || 'cs';
			this.$root.fulltextEnabled = this.serverSettings.fulltext_enabled || false;
			this.init();
		},
		setHomePageRoutes() {
			if (this.serverLoaded && !$_.isEmpty(this.$root.defaultHomePage)) {
				if (this.$root.defaultHomePage === 'blank') {
					this.$root.homeRoute = { name: 'home' };
					this.$root.$emit('home-route-changed');

				} else if (this.$root.defaultHomePage === 'route' && !$_.isEmpty(this.$root.defaultHomePageRoute)) {
					this.$root.homeRoute = { path: this.$root.defaultHomePageRoute };
					this.$root.$emit('home-route-changed');

				} else if (this.$root.defaultHomePage === 'dashboard' && this.$user.loadedModules.includes('dashboard')) {
					this.$root.homeRoute = { name: 'dashboard', params: { id: 'home-dashboard' } };
					this.$root.$emit('home-route-changed');

				} else if (this.$root.defaultHomePage === 'first_menu_item') {
					if (this.$root.firstMenuItemRoute.name) {
						this.$root.homeRoute = { name: this.$root.firstMenuItemRoute.name };
					} else if (this.$root.firstMenuItemRoute.path) {
						this.$root.homeRoute = { path: this.$root.firstMenuItemRoute.path };
					} else {
						// this item is properly set in LbaMenu.vue after menu is loaded
						this.$root.homeRoute = { name: 'home' };
						this.$root.$emit('home-route-changed');
					}

				}
				console.debug('Default home page:', this.$root.defaultHomePage,
					', default home page route:', this.$root.defaultHomePageRoute);
				console.debug('Home route:', this.$root.homeRoute);
			}
		},
		initSettingsDialog() {
			this.initColorThemes();
		},
		initForceChangeDialog(event) {
			if (!this.$refs.force_password_change || !this.$refs.force_2fa_change) {
				setTimeout(() => {
					this.initForceChangeDialog();
				}, 500);
			} else {
				const serverSettings = $_.first(this.$root.servers);
				if (this.$user.force_password_change === 'true' || this.$user.force_password_change === true) {
					this.$root.$emit('dialog-open', { name: 'force_password_change' });
				} else if ((['forced', 'noLogin'].includes(this.$user.tfa_required) ||
					(this.$user.tfa_required === null && this.$user.tfa_required_global !== 'false')) &&
					!this.$user.tfa.length && serverSettings.tfa && serverSettings.tfa.length &&
					this.$user.user_name !== 'anonymous'
				) {
					this.$root.$emit('dialog-open', { name: 'force_2fa_change' });
				}
			}
		},
		initPasswordChangeDialog() {
			if (this.$user.force_password_change === 'true' || this.$user.force_password_change === true) {
				this.$root.$emit('dialog-open', { name: 'force_password_change' });
			}
		},
		checkServers() {
			this.$root.servers.forEach((server) => {
				// is timeDiff enabled?
				if (server.timeDiff && server.timeDiff > 10000 && server.timeCheckEnabled) {
					this.$notify.warn(this.$t('badClientTime'));
				}
				// shoul I ask for notifications permissions on start?
				if (server.askNotifications && !this.$desktopNotify.isInitialized) {
					this.$desktopNotify.init();
				}
			});
		},
		toggleOverlay() {
			this.overlay = !this.overlay;
			this.$root.$emit('toggle-overlay', this.overlay);
		},
		async reloadWhenPermissionDenied() {
			await this.$nextTick();
			if (
				this.getErrorType() === 'permissionDenied' ||
				(this.$root.$tabs && this.$root.$tabs.anyTabViewError(this.$root.$tabs.activeTab, 'permissionDenied'))
			) {
				this.$root.$emit('tabs.reload', true);
			}
		},
		async userUpdated() {
			this.permissionsReload();
			this.theme = this.$user.color_theme || 'theme-basic';
			this.$user.is_dark_theme = this.isDarkTheme(this.theme);
			this.$root.$emit('theme-changed');
			this.$forceUpdate();
			await this.$nextTick();
			this.initSettingsDialog();
			this.initForceChangeDialog();
			this.addAvoided('firstname', this.$user.firstname, this.$t('settings.user.firstname'));
			this.addAvoided('lastname', this.$user.lastname, this.$t('settings.user.lastname'));
			this.addAvoided('login', this.$user.id, this.$t('settings.user.login'));
			this.addAvoided('email', this.$user.email, this.$t('settings.user.email'));
		},
		logout() {
			this.theme = 'theme-basic';
			this.$user.is_dark_theme = this.isDarkTheme(this.theme);
			this.$root.$emit('theme-changed');
			// clear temp variables for force password change
			this.password = null;
			this.current_password = null;
			this.$root.$emit('dialog-close', 'force_password_change');
			this.$root.$emit('dialog-close', 'force_2fa_change');
			// logout user
			this.$user.logout();
			const { href } = this.$router.resolve({ name: 'login' });
			if (!this.$root.isFirstLogin) {
				window.history.pushState({}, null, href);
				this.reload();
			} else {
				this.$routerWrap.push('/login');
			}
		},
		reload() {
			window.location.reload();
		},
		async onSubmit(force = false) {
			if (!force) {
				const valid = await this.$refs.formQuickSetting.validate();
				if (!valid) {
					this.$root.$emit('content.validationFailed');
					return;
				}
			} else {
				const valid = await this.$refs.formForcedPassChange.validate();
				if (!valid) {
					this.$root.$emit('content.validationFailed');
					return;
				}
			}
			this.$user.color_theme = this.theme;
			this.$user.is_dark_theme = this.isDarkTheme(this.theme);

			this.$user.updateUser().then(() => {
				this.$user.load(false).then(() => {
					if (this.$user.current_password && this.$user.password) {
						delete this.$user.current_password;
						delete this.$user.password;
						this.logout();
						this.$root.$emit('dialog-close', 'force_password_change');
						this.$root.$emit('dialog-close', 'force_2fa_change');
						this.$notify.success(this.$t('passChanged'));
					} else {
						this.$root.$emit('content.saved', { reload: false });
					}
					this.$root.$emit('user.updated');
					this.$root.$emit('user.settings-updated');
				});
			// already handled in $http interceptors
			}).catch(() => {});
		},
		onIdle() {
			if (!this.$user.user_name || this.$user.is_anonymous_user) {
				return;
			}
			this.$root.$emit('dialog-open', { name: 'idle_dialog' });
			this.idleCountdownTime = 300000;
			this.idleStartTime = new Date();
			this.idleCountdownInterval = setInterval(() => {
				this.idleCountdownTime = this.idleStartTime.getTime() + 300000 - new Date().getTime();
				if (this.idleCountdownTime < 1) {
					this.$root.$emit('dialog-close', 'idle_dialog');
					clearInterval(this.idleCountdownInterval);
					this.idleCountdownInterval = null;
					this.logout();
				}
			}, 1000);
		},
		onActive() {
			this.$root.$emit('dialog-close', 'idle_dialog');
			if (this.idleCountdownInterval) {
				clearInterval(this.idleCountdownInterval);
			}
			this.idleStartTime = null;
		},
		onFullscreenChange() {
			this.$root.setIsFullscreen();
			if (!this.$root.isFullscreen) {
				document.body.classList.remove('fullscreen');
			}
		},
		onGoFullscreen() {
			document.body.classList.add('fullscreen');

			if (this.$root.isFullscreen) return;
			const { documentElement } = document;
			if (documentElement.requestFullscreen) {
				documentElement.requestFullscreen();
			} else if (documentElement.mozRequestFullScreen) {
				documentElement.mozRequestFullScreen();
			} else if (documentElement.webkitRequestFullScreen) {
				documentElement.webkitRequestFullScreen();
			} else if (documentElement.msRequestFullscreen) {
				documentElement.msRequestFullscreen();
			}
		},
		onExitFullscreen() {
			document.body.classList.remove('fullscreen');

			if (!this.$root.isFullscreen) return;
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		},
		/**
		 * @param {String} uid Uid may be route path or some uid.
		 */
		preventClosing(uid) {
			if (!this.dirtyUids.length) {
				window.addEventListener('beforeunload', this.closeListener, true);
			}
			if ((this.dirtyUids.indexOf(uid) === -1)) {
				this.dirtyUids.push(uid);
			}
		},
		/**
		 * @param {String} uid Uid may be route path or some uid.
		 */
		allowClosing(uid) {
			if ($_.isEmpty(uid)) {
				console.error('[lbadmin.App](allowClosing) uid is empty');
				return;
			}

			this.dirtyUids = this.dirtyUids.filter((el) => el !== uid);
			if (!this.dirtyUids.length) {
				window.removeEventListener('beforeunload', this.closeListener, true);
			}
		},
		allowClosingUids(uids) {
			if ($_.isEmpty(uids)) {
				console.error('[lbadmin.App](allowClosing) uids are empty');
				return;
			}

			this.dirtyUids = this.dirtyUids.filter((uid) => !uids.includes(uid));
			if (!this.dirtyUids.length) {
				window.removeEventListener('beforeunload', this.closeListener, true);
			}
		},
		async openSettings() {
			this.$routerWrap.push({ name: 'user-settings', params: { openNewTab: true } });
		},
		onForcedPassChange() {
			this.$user.password = this.password;
			this.$user.current_password = this.current_password;
			this.password = null;
			this.current_password = null;
			this.onSubmit(true);
		},
		async openAbout() {
			const resp = await this.$http.get(`lbadmin/about`);
			this.about = resp.data;
			this.$root.$emit('dialog-open', { name: 'about' });
		},
		openChangelog() {
			this.$routerWrap.push({ name: 'changelog', params: { openNewTab: true } });
		},
		openDocumentation() {
			this.$routerWrap.push({ name: 'documentation', params: { openNewTab: true } });
		},
		addAvoided(key, value, label) {
			this.avoidedItemsWhole[key] = { value, label };

			const arr = [];
			$_.forEach(this.avoidedItemsWhole, (el) => {
				if (el.value && el.value.length > 2) arr.push(el.value);
			});

			this.avoidedItems = arr;
		},
		onDialogOpen(opts) {
			if (opts.name === 'settings') {
				this.initColorThemes();
			}
		},
		async openSuMode() {
			this.suUser = this.$user.suUser || null;

			const resp = await this.userModel.getSuUsers();
			this.suUsers = resp.data;

			this.switchUserDirty = false;
			this.$root.$emit('dialog-open', { name: 'suMode' });
		},
		async cancelSuMode() {
			await this.userModel.resetSu();
			this.reload();
		},
		setSuMode() {
			// set su mode
			if (this.$user.suUser === null) {
				this.userModel.resetSu();
			} else {
				this.userModel.setSuUser(this.suUser)
					.then((err) => {
						console.error(err);
						this.$notify.success(this.$t('suSuccess'));
						this.$user.suUser = this.suUser;
						this.$root.$emit('dialog-close', 'suMode');
						this.reload();
					})
					.catch((err) => {
						console.error(err);
						this.$notify.warn(this.$t('suError'));
						this.suUser = this.$user.suUser;
					});
			}
		},
		closeSuMode() {
			this.suUser = this.$user.suUser;
			this.$root.$emit('dialog-close', 'suMode');
		},
		isClipboardAvailable() {
			return (navigator && navigator.clipboard);
		},
		copyModulesVersions() {
			if (this.about && this.about.version) {
				const versions = [
					`url: ${window.location.href}`,
					`browser: ${(navigator && navigator.userAgent) || '-'}`,
					`user: ${(this.$user && this.$user.user_name) || '-'}`,
					`versions:`,
					`  lbadmin: ${this.about.version}`,
				];
				if (this.about.modules) {
					$_.forEach(this.about.modules, (module) => {
						versions.push(`  ${module.module}: ${module.version}`);
					});
				}
				const versionsJoin = versions.join('\n');
				if (navigator && navigator.clipboard) {
					navigator.clipboard.writeText(versionsJoin);
					this.$notify.success(this.$t('settings.copied'));
				}
			}
		},
	},
};
</script>
