<template>
	<button
		type="button"
		class="button button-save"
		:class="{
			'is-busy': isAnimating,
			'buttonRed': notSaved,
			disabled: isDisabled,
		}"
		v-tooltip="notSaved || dataChanged ? internalTooltip : tooltip"
		@click="onClick"
	>
		<slot
			name="default"
			:buttonText="buttonText"
			:isAnimating="isAnimating"
			:isDisabled="isDisabled"
			:onClick="onClick"
		>
			{{ buttonText }}
		</slot>
	</button>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
		},
		disabled: {
			type: Boolean,
		},
		inputDirty: {
			type: Boolean,
		},
		saveUid: {
			type: String,
		},
		routeName: {
			type: String,
		},
		saveLabel: {
			type: String,
		},
		mode: {
			type: String,
			default: 'prop',
			validate(value) {
				return value === 'prop' || 'event';
			},
		},
		tooltip: null,
		failedTooltip: null,
	},
	data() {
		return {
			notSaved: false,
			dataChanged: false,
			isBusy: false,
			buttonText: this.saveLabel || this.$t('save'),
			internalTooltip: null,
			keepAnimating: false,
			animationPromise: null,
			onSavePromise: null,
		};
	},
	computed: {
		isAnimating() {
			return this.isBusy || this.keepAnimating;
		},
		isDisabled() {
			return (this.disabled || this.notSaved || this.dataChanged || !this.inputDirty) && !this.isAnimating;
		},
	},
	watch: {
		async inputDirty() {
			this.onInputDirty(this.id);
		},
	},
	created() {
		if (this.mode === 'prop' && this.inputDirty == null) {
			throw new Error('[LbaButtonSave] when mode is "prop" then inputDirty dirty is required');
		}
		this.$root.$listen('content.inputDirty', this.onInputDirty, this);
		this.$root.$listen('content.validationFailed', (info) => this.onSaveFailed(info, 'validation'), this);
		this.$root.$listen('content.saveFailed', (info) => this.onSaveFailed(info, 'server'), this);
		this.$root.$listen('content.saveCanceled', this.onSaveCanceled, this);
		this.$root.$listen('content.saved', this.onSaveSuccess, this);
		this.$root.$listen('entry.saved', this.onEntrySaved, this, true);
	},
	methods: {
		async onEntrySaved(event) {
			if (!('saveUid' in this)) {
				console.error('[LbaButtonSave] missing saveUid property');
				return;
			}

			if (!('routeName' in this)) {
				console.error('[LbaButtonSave] missing routeName property');
				return;
			}

			// same browser & lbadmin tab
			if (event.source !== 'socket' && event.routeName === this.routeName) {
				return;
			}

			// same entry uid
			if (event.uid === this.saveUid) {
				this.buttonText = this.saveLabel || this.$t('save');
				this.notSaved = false;
				this.dataChanged = true;
				this.internalTooltip = this.$t('dataChangedPleaseRefreshPage');
				this.$emit('data-changed', true);
			}
		},
		async onInputDirty(id) {
			if (id !== this.id) {
				return;
			}
			if (this.inputDirty) {
				if (this.animationPromise) {
					await this.animationPromise;
					this.animationPromise = null;
				}
				if (this.onSavePromise) {
					await this.onSavePromise;
					this.onSavePromise = null;
				}
				// console.debug('[LbaButtonSave](inputDirty)', this.id);
				if (!this.dataChanged) {
					this.internalTooltip = null;
				}
				this.buttonText = this.saveLabel || this.$t('save');
				this.notSaved = false;
			}
		},
		async onSaveSuccess(info) {
			if (
				(!$_.isEmpty(this.id) && this.id !== info.id) ||
				(!$_.isEmpty($_.get(info, 'id')) && this.id !== info.id)
			) {
				return;
			}
			let resolveOnSavePromise = null;
			this.onSavePromise = new Promise((resolve) => {
				resolveOnSavePromise = resolve;
			});
			if (this.animationPromise) {
				await this.animationPromise;
				this.animationPromise = null;
			}
			// console.debug('[LbaButtonSave](onSaveSuccess)', this.id);
			this.isBusy = false;
			this.buttonText = this.$t('saved');
			resolveOnSavePromise();
			this.onSavePromise = null;
		},
		async onSaveFailed(info, type) {
			if (
				(!$_.isEmpty(this.id) && this.id !== info.id) ||
				(!$_.isEmpty($_.get(info, 'id')) && this.id !== info.id)
			) {
				return;
			}
			let resolveOnSavePromise = null;
			this.onSavePromise = new Promise((resolve) => {
				resolveOnSavePromise = resolve;
			});
			if (this.animationPromise) {
				await this.animationPromise;
				this.animationPromise = null;
			}
			// console.debug('[LbaButtonSave](onSaveFailed)', this.id);
			this.isBusy = false;
			this.buttonText = this.$t('notSaved');
			this.notSaved = true;

			if (type === 'validation') {
				this.internalTooltip = this.$t('form.validation_failed');
			} else if (type === 'server') {
				this.internalTooltip = this.$t('form.save_failed');
			} else {
				this.internalTooltip = this.failedTooltip || null;
			}
			resolveOnSavePromise();
			this.onSavePromise = null;
		},
		async onSaveCanceled(info) {
			if (
				(!$_.isEmpty(this.id) && this.id !== info.id) ||
				(!$_.isEmpty($_.get(info, 'id')) && this.id !== info.id)
			) {
				return;
			}
			let resolveOnSavePromise = null;
			this.onSavePromise = new Promise((resolve) => {
				resolveOnSavePromise = resolve;
			});
			if (this.animationPromise) {
				await this.animationPromise;
				this.animationPromise = null;
			}
			// console.debug('[LbaButtonSave](onSaveCanceled)', this.id);
			this.isBusy = false;
			this.notSaved = false;
			this.buttonText = this.saveLabel || this.$t('save');
			resolveOnSavePromise();
			this.onSavePromise = null;
		},
		onClick(event) {
			if (this.dataChanged) {
				this.$notify.warn(this.$t('dataChangedPleaseRefreshPage'));
				return;
			}
			if (this.disabled || !this.inputDirty || this.notSaved || this.isBusy || this.keepAnimating) {
				return;
			}
			// console.debug('[LbaButtonSave](onClick)', this.id);
			this.buttonText = this.$t('saving');
			this.isBusy = true;
			this.keepAnimating = true;
			this.animationPromise = new Promise((resolve) => {
				setTimeout(() => {
					this.cancelAnimation();
					resolve();
				}, 500);
			});
			this.$emit('click', event);
			return true;
		},
		cancelAnimation() {
			// console.debug('[LbaButtonSave](cancelAnimation)', this.id);
			this.keepAnimating = false;
		},
	},
};
</script>
