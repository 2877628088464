var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.noInput)?_c(_vm.rootTag,{tag:"component",class:_vm.classes,attrs:{"data-cy":_vm.currentComponentId}},[_vm._v(" "+_vm._s(_vm.data[_vm.key])+" ")]):_c('s',{class:( _obj = {}, _obj[_vm.classes] = true, _obj['has-units'] =  _vm.units.length > 0, _obj ),style:(_vm.style),attrs:{"data-cy":_vm.currentComponentId}},[_c('small',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.isRequired)?_c('span',{staticClass:"required-sign"}):_vm._e(),(_vm.titleTooltip)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.titleTooltip),expression:"titleTooltip"}],staticClass:"icon-tooltip"}):_vm._e()]),_c('fake-provider',{ref:_vm.inputRef,attrs:{"name":_vm.$getLocale(_vm.label),"vid":((_vm.$getLocale(_vm.label)) + "-" + _vm.inputRef),"rules":_vm.rules,"immediate":"","data-required-recommended":_vm.requiredRecommended},scopedSlots:_vm._u([{key:"default",fn:function(ref){
	var invalid = ref.invalid;
	var errors = ref.errors;
return [_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.data[_vm.key]),expression:"data[key]"}],staticClass:"showInvalidTooltip",class:{
				'lba-invalid': (invalid && _vm.dirty),
				'showWarning': (_vm.showRecommendation)
			},attrs:{"data-cy":(_vm.currentComponentId + "__inputNumber"),"type":"number","disabled":_vm.readOnly},domProps:{"value":(_vm.data[_vm.key])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, _vm.key, $event.target.value)},function($event){return _vm.onEvent('input', _vm.parseString)}],"change":function($event){return _vm.onEvent('change', _vm.parseNumber)},"blur":function($event){return _vm.$emit('blur')}}},'input',_vm.bindOptions(_vm.numberOptions),false)),(_vm.dirty)?[_vm._l((errors),function(err,index){return _c('span',{key:index,staticClass:"lba-messages",attrs:{"data-cy":(_vm.currentComponentId + "__error" + index)}},[_vm._v(" "+_vm._s(err)+" ")])}),_vm._l((_vm.customMessages),function(msg,index){return _c('span',{key:index,staticClass:"lba-messages warning",attrs:{"data-cy":(_vm.currentComponentId + "__error" + index)}},[_vm._v(" "+_vm._s(msg)+" ")])})]:_vm._e(),(_vm.units.length > 0)?_c('span',{staticClass:"num-units"},[_vm._v(_vm._s(_vm.units))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }