<template>
	<ckeditor
		:data-cy="`${currentComponentId}__ckeditor`"
		v-model="internalValue"
		:editor="$ClassicEditor"
		:config="editorConfig"
		:disabled="disabled"
		@blur="onBlur"
		@ready="setCkEditorReady"
		:ref="componentRef"
	></ckeditor>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

class MyUploadAdapter {
	constructor(loader, callback) {
		this.loader = loader;
		this.callback = callback;
	}

	upload() {
		// Update the loader's progress.
		// server.onUploadProgress((data) => {
		// 	this.loader.uploadTotal = data.total;
		// 	this.loader.uploaded = data.uploaded;
		// });
		const callback = this.callback;

		// Return a promise that will be resolved when the file is uploaded.
		return this.loader.file
			.then((file) => {
				if (callback) {
					callback(file);
				}
				return file;
			});
	}

	// Aborts the upload process.
	abort() {
		// Reject the promise returned from the upload() method.
		// server.abortUpload();
	}
}

export default {
	name: 'LbaCKEditor',
	mixins: [ComponentIdentifier],
	model: {
		prop: 'editorContent',
	},
	props: {
		editorContent: {
			type: String,
		},
		callback: {
			type: Function,
		},
		config: {
			type: Object,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		componentRef: String,
	},
	computed: {
		internalValue: {
			get() {
				if (this.isCkEditorReady) {
					return this.editorContent || '';
				}
				return '';
			},
			set(value) {
				if (!this.isCkEditorReady) {
					if (!$_.isEmpty(this.editorContent)) {
						this.doInputEmit = false;
					}
					return;
				}

				if (!this.doInputEmit) {
					this.doInputEmit = true;
					return;
				}

				if (
					(
						$_.isEmpty(this.editorContent) &&
						$_.isEmpty(value)
					) ||
					value === this.editorContent
				) {
					return;
				}

				this.$emit('editorContent', value);
				this.$emit('input', value);
			},
		},
	},
	data() {
		return {
			editorConfig: {
				link: {
					addTargetToExternalLinks: true,
				},
				heading: {
					options: [
						{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
						{ model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1' },
						{ model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2' },
						{ model: 'heading3', view: 'h4', title: 'Heading 3', class: 'ck-heading_heading3' },
						{
							model: 'monospace',
							view: { name: 'p', classes: 'monospace' },
							title: this.$t('ckEditorParagraphMonospace'),
							class: 'ck-heading_monospace',
							converterPriority: 'high',
						},
						{
							model: 'code',
							view: { name: 'p', classes: 'code' },
							title: this.$t('ckEditorParagraphCode'),
							class: 'ck-heading_code',
							converterPriority: 'high',
						},
					],
				},
				typing: {
					transformations: {
						include: [],
					},
				},
				language: this.$user.lang,
				toolbarLocation: 'top',
				removePlugins: ['Autoformat', 'CKFinder', 'EasyImage', 'Image', 'ImageUpload', 'MediaEmbed'],
			},
			internalAttachments: [],
			lastBlurValue: '',
			isCkEditorReady: false,
			doInputEmit: true,
			enterMode: 'shiftEnter',
		};
	},
	async created() {
		this.editorConfig = {
			...this.editorConfig,
			...this.config,
		};
		this.lastBlurValue = this.editorContent || '';
		this.$root.$listen('ck-editor-insert-text', this.insertPending, this);
		this.$root.$listen('user.updated', this.changeEnterMode, this);
		this.changeEnterMode();
	},
	methods: {
		onBlur() {
			if (this.lastBlurValue !== this.internalValue) {
				this.lastBlurValue = this.internalValue;
				this.$emit('change', this.internalValue);
			}
			this.$emit('blur');
		},
		addFileInternal(file) {
			this.internalAttachments.push(file);
			this.$emit('callback', file);
		},
		MyUploadAdapterPlugin() {
			return (function (t) {
				return function (editor) {
					editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
						return new MyUploadAdapter(loader, t);
					};
				};
			})(this.addFileInternal);
		},
		insertPending(params) {
			if (params && (params.ref === this.componentRef) && params.value) {
				const editor = this.$refs[this.componentRef].$_instance;
				if (editor) {
					editor.editing.view.focus();
					editor.model.change((writer) => {
						writer.insertText(
							params.value,
							editor.model.document.selection.getFirstPosition()
						);
					});
				}
			}
		},
		changeEnterMode() {
			this.enterMode = (this.$user.ckeditor_enter_mode === 'ENTER_BREAK') ? 'enter' : 'shiftEnter';
		},
		setCkEditorReady(editor) {
			this.isCkEditorReady = true;
			const view = editor.editing.view;

			view.document.on(
				'enter',
				(evt, data) => {

					if (this.enterMode === 'enter') {
						editor.execute((data.domEvent.shiftKey) ? 'enter' : 'shiftEnter');

					} else {
						editor.execute((data.domEvent.shiftKey) ? 'shiftEnter' : 'enter');

					}

					view.scrollToTheSelection();

					// Cancel existing event
					evt.preventDefault();
					evt.stop();
				}, { priority: 'high' });
		},
	},
};
</script>
