export default {
	props: {
		searchInput: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data() {
		return {
			searchValue: '',
			isSearching: false,
			searchDebouncer: null,
		};
	},
	created() {
		this.searchDebouncer = new this.$Debouncer(
			this,
			this.fetchCollection,
			this.toggleIsSearching,
			500
		);
	},
	methods: {
		searchValueEmpty() {
			return this.searchValue == null || this.searchValue === '';
		},
		toggleIsSearching(state) {
			this.isSearching = state;

			if (this.isSearching) {
				this.viewport = [];
				this.offsetTop = 0;
			}
		},
		onSearchRowInput(columnId) {
			if (this.collection.search[columnId] === null || this.collection.search[columnId] === '') {
				delete this.collection.search[columnId];
			}
			this.searching();
		},
		searching() {
			this.searchDebouncer.emit(0, true, true);
		},
		async clearSearchValue() {
			this.searchValue = null;
			await this.fetchCollection(0, true, true);
		},
		async clearSearch() {
			this.searchValue = null;
			this.collection.search = {};
			const filterColumns = this.getFilterColumns(this.filterAttributes.columns, true);
			$_.forEach(Object.keys(this.collection.filter), (filterName) => {
				const filterColumn = $_.find(filterColumns, (item) => item.name === filterName && !item.required);
				if (filterColumn) {
					delete this.collection.filter[filterName];
				}
			});
			const newFilterShow = [];
			$_.forEach(this.filterShow, (filterShow) => {
				const filterColumn = $_.find(filterColumns, (item) => item.name === filterShow.name && !item.required);
				if (!filterColumn) {
					newFilterShow.push(filterShow);
				}
			});
			this.filterShow = newFilterShow;
			this.cleanFilter();
			this.usedFilterName = null;
			this.usedFilterIsGlobal = false;
			if (this.hiddenSearch) {
				await this.removeHiddenSearch({ type: 'clearAll' });
			} else {
				await this.fetchCollection(0, true, true);
			}
			await this.$nextTick();
			this.resize();
			// this.setViewportHeight();
		},
		async removeHiddenSearch(search) {
			const keys = Object.keys(this.collection.search);
			let keyword;
			if (search && search.type && search.type !== 'clearAll') {
				const keywords = search.type.split('_');
				keyword = keywords[1];
			}

			keys.map((key) => {
				if ((keyword && key.includes(keyword)) || search.type === 'clearAll') {
					delete this.collection.search[key];
				}
			});

			if (search.type === 'clearAll') {
				this.hiddenSearch = [];
			} else {
				this.hiddenSearch = this.hiddenSearch.filter(
					(s) => !s.type.includes(search.type)
				);
			}

			if ($_.isEmpty(this.hiddenSearch) && this.collection.search._join_subject) {
				delete this.collection.search._join_subject;
			}
			await this.fetchCollection(0, true, true);
		},
	},
};
