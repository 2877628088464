<template>
	<div
		:class="{ 'section-invalid': !expand && sectionInvalid }"
		ref="root"
		@change="checkInvalidSection"
		@keyup="checkInvalidSection"
		@click="checkInvalidSection"
		@focusout="checkInvalidSection"
	>
		<div
			class="section expander"
			:class="{ expanded: expand }"
			ref="expander"
			:data-cy="`${currentComponentId}__toggleExpand`"
		>
			<div class="section-inner" @click="toggle">
				<h2>{{ title }}</h2>
				<i class="icon-tooltip" v-if="tooltip" v-tooltip="tooltip"></i>
				<span v-html="titleHtml"></span>
				<span v-show="changed" class="stamp stampEdit" :data-cy="`${currentComponentId}__edited`">upraveno</span>
				<span v-show="invalid && changed" class="stamp stampEdit" :data-cy="`${currentComponentId}__invalid`">nevalidní</span>
			</div>
			<div class="section-controls" @click.stop>
				<slot name="controls" :expanded="expanded" :expand="expand" :changed="changed" :invalid="invalid" :openSection="openSection"
					:parentComponentId="`${currentComponentId}__slotControls`"
				></slot>
			</div>
		</div>
		<div v-show="expand"
			class="expand "
			ref="content"
			:class="[
				slotType,
				scrollable ? 'scrollable' : '',
			]"
		>
			<slot :expanded="expanded" :expand="expand" :changed="changed" :invalid="invalid" :openSection="openSection"
				:parentComponentId="`${currentComponentId}__slotDefault`"
			></slot>
		</div>
		<div
			v-if="!expand && sectionInvalid"
			class="lba-messages invalid-field-tooltip"
			:class="{ invalidFieldTooltip: sectionInvalid }"
			:data-cy="`${currentComponentId}__invalid`"
		>
			{{$t('form.validation_failed')}}
		</div>
	</div>
</template>

<style>
.h-info {
	color: #999;
	font-size: 10px;
	top: 1px;
	left: 2px;
}
.h-info:hover {
	color: #000;
}
</style>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';

export default {
	mixins: [ComponentIdentifier],
	props: {
		externalId: {
			type: String,
		},
		name: {
			type: String,
		},
		title: {
			type: String,
			required: false,
			default: '',
		},
		titleHtml: {
			type: String,
			required: false,
			default: '',
		},
		slotType: {
			type: String,
			required: false,
			default: 'text',
		},
		expanded: {
			type: Boolean,
			required: false,
			default: null,
		},
		changed: {
			type: Boolean,
			required: false,
			default: false,
		},
		invalid: {
			type: Boolean,
			required: false,
			default: false,
		},
		tooltip: {
			type: String,
		},
		extend: Boolean,
		scrollable: Boolean,
		storeSettings: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			slotTypeCache: null,
			expand: null,
			slotTypeSet: false,
			styleSet: false,
			attemptCount: 0,
			minHeight: 0,
			id: $generateUID(),
			settings: null,
			sectionInvalid: false,
		};
	},
	watch: {
		expanded() {
			if (Boolean(this.expanded) !== this.expand) {
				this.toggle();
			}
		},
	},
	created() {
		this.$root.$listen('content.validationFailed', this.onEntryValidationFailed, this);
		this.$root.$listen('section-change-state', this.changeExpanded, this);

		if (this.name && this.storeSettings) {
			this.settings = this.$pageSettings.get('LbaSection', this.name);
			if (!$_.isEmpty(this.settings)) {
				this.expand = this.settings.expand;
				return;
			}
		}
		if (this.expand == null && this.expanded != null) {
			this.expand = this.expanded;
		}
		if (this.expand == null) {
			this.expand = false;
		}
		if (this.settings) {
			this.settings.expand = this.expand;
		}
	},
	async mounted() {
		this.setSlotTypeCache();
		if (this.extend) {
			this.observer = new MutationObserver(this.setHeight);
			this.observer.observe(this.$refs.root?.parentElement, { subtree: true, childList: true });
			await this.$nextTick();
			this.setHeight();
		}
		/* if (this.extend) {
			this.$root.$listen('lba-section.toggle', this.onSectionToggle, this);
		} */

		// remove default invalid tooltip
		const contentInvalidTooltips = this.$refs.content?.querySelectorAll('.lba-messages');
		if (contentInvalidTooltips?.length > 1) {
			contentInvalidTooltips[0].remove();
		}
	},
	methods: {
		/* async onSectionToggle(event) {
			if (event.id !== this.id) {
				await this.$nextTick();
				this.setHeight();
			}
		}, */
		setSlotTypeCache() {
			if (this.slotType !== 'text' && this.slotType !== 'form') {
				this.slotTypeCache = 'text';
			} else {
				this.slotTypeCache = this.slotType;
			}
		},
		async toggle() {
			this.expand = !this.expand;
			this.$emit('expand', this.expand);
			if (this.settings) {
				this.settings.expand = this.expand;
			}

			if (this.name) {
				this.$root.$emit('page-settings.change', { componentName: 'LbaSection', name: this.name });
			}

			if (this.extend) {
				if (!this.expand) {
					this.$refs.root.setAttribute('style', `min-height: none;`);
				} else {
					this.setHeight();
				}
			}

			// await this.$nextTick();
			// this.$root.$emit('lba-section.toggle', { expand: this.expand, id: this.id });

		},
		async setHeight() {
			const pageContentPadding = 20;
			const sectionMargin = 20;
			const sectionContentPadding = 10;
			const bottomMargin = pageContentPadding + sectionMargin + sectionContentPadding;
			let rootBounding;
			let pageContentBounding;

			try {
				rootBounding = this.$refs.root?.getBoundingClientRect();
				pageContentBounding = document.getElementsByClassName('page-content')[0].getBoundingClientRect();
			} catch {
				this.attemptCount += 1;

				if (this.attemptCount > 3) {
					return;
				}
				await this.$nextTick();
				return this.setHeight();
			}

			this.minHeight = pageContentBounding.height + pageContentBounding.top - rootBounding.top - bottomMargin;
			/* console.log(
				this.title,
				'h:',
				this.minHeight,
				'pch:',
				pageContentBounding.height,
				'pct:',
				pageContentBounding.top,
				'rbt:',
				rootBounding.top,
				'bm:',
				bottomMargin
			); */
			if (this.expand) {
				this.$refs.root.setAttribute('style', `min-height: ${this.minHeight}px;`);
			}
		},
		openSection() {
			const expandChanged = !this.expand;
			this.expand = true;
			if (this.settings) {
				this.settings.expand = this.expand;
			}
			if (this.name && expandChanged) {
				this.$root.$emit('page-settings.change', { componentName: 'LbaSection', name: this.name });
			}
		},
		onEntryValidationFailed() {
			this.checkInvalidSection();
		},
		checkInvalidSection() {
			const fields = this.$refs.root?.querySelectorAll('.lba-invalid');
			this.sectionInvalid = fields?.length > 0;
		},
		changeExpanded(externalId, state) {
			if (this.externalId === externalId) {
				this.expand = state;
			}
		},
	},
};
</script>
