var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"component",class:{
		dateTime: _vm.$_.isEmpty(_vm.mode) || _vm.mode === 'datetime' || _vm.mode === 'future-datetime',
		calendarDateTime: _vm.$_.isEmpty(_vm.mode) || _vm.mode === 'datetime' || _vm.mode === 'future-datetime',
		calendarDate: _vm.mode === 'date',
		calendarTime: _vm.mode === 'time',
		input: true,
	},attrs:{"id":_vm.id}},[_c('div',{class:{'inputDate-invalid': _vm.invalid}},[_c('vue-date-picker',{key:_vm.calendarUid,attrs:{"data-cy":(_vm.currentComponentId + "__vue-pikaday"),"attributes":_vm.attributes,"min-date":_vm.minDate,"max-date":_vm.maxDate,"popover":_vm.popover,"locale":_vm.locale,"timezone":_vm.internalMode === 'date' ? null : _vm.$user.timezone,"input-debounce":_vm.inputDebounce,"mode":_vm.internalMode,"is24hr":_vm.$user.lang === 'cs',"masks":{
				L: 'DD.MM.YYYY',
				M: 'H:mm',
				input: [_vm.internalPlaceholder, 'L','DD.MM.YYYY H:mm'],
				inputDateTime24hr: [_vm.internalPlaceholder, 'L', 'DD.MM.YYYY'],
				inputTime24hr: [_vm.internalPlaceholder, 'M', 'H:mm'],
				inputDateTime: [_vm.internalPlaceholder, 'L', 'DD.MM.YYYY'],
				data: [_vm.internalPlaceholder, 'L', 'DD.MM.YYYY H:mm'],
			}},on:{"input":function($event){return _vm.$emit('input', _vm.date)},"update":function($event){return _vm.$emit('update', _vm.date)},"~input":function($event){return _vm.onTouch($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var inputValue = ref.inputValue;
			var inputEvents = ref.inputEvents;
return [_c('span',_vm._g({staticClass:"inputDate",class:{
						'inputDate-invalid': _vm.invalid
					}},!_vm.readOnly ? inputEvents : null),[_c('input',{ref:"dateInput",class:{
							'ng-invalid': _vm.required && _vm.value == null && _vm.dirty,
							'date-invalid': _vm.invalid
						},attrs:{"data-cy":(_vm.currentComponentId + "__date__inputText"),"disabled":_vm.readOnly,"placeholder":_vm.internalPlaceholder},domProps:{"value":inputValue}}),(!_vm.readOnly)?[(_vm.allowDelete)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('deleteDT')),expression:"$t('deleteDT')"}],staticClass:"calendarEmpty",attrs:{"type":"button","tabindex":"-1","data-cy":(_vm.currentComponentId + "__clear")},on:{"click":function($event){$event.stopPropagation();return _vm.clear($event)}}},[_c('i',{staticClass:"icon-cancel"})]):_vm._e(),_c('i',{class:{
							'icon-calendar': ['date', 'dateTime'].includes(_vm.internalMode),
							'icon-clock': _vm.internalMode === 'time',
						},on:{"click":function($event){$event.stopPropagation();_vm.$refs.dateInput && _vm.$refs.dateInput.click();}}})]:_vm._e()],2)]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"p-2 buttonContainer"},[_c('button',{staticClass:"buttonInverse buttonSmall",attrs:{"data-cy":(_vm.currentComponentId + "__now"),"type":"button"},on:{"click":function($event){$event.stopPropagation();_vm.setTimeToNow(); _vm.$emit('input', _vm.date); _vm.$emit('change', _vm.date)}}},[_vm._v(" "+_vm._s(_vm.$t('now'))+" ")]),_c('button',{staticClass:"button buttonSmall orangeButton ml-3",attrs:{"data-cy":(_vm.currentComponentId + "__close"),"type":"button"},on:{"click":function($event){$event.stopPropagation();_vm.$refs.dateInput.click(); _vm.$emit('input', _vm.date); _vm.$emit('change', _vm.date)}}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])])]},proxy:true}]),model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),(_vm.invalid)?_c('span',{staticClass:"lba-messages"},[_vm._v(_vm._s(_vm.invalid))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }