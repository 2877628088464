<template>
	<component
		v-if="noInput"
		:is="rootTag"
		:class="classes"
		:style="style"
	>
		{{ noInputData() }}
	</component>
	<s
		v-else
		:class="classes"
		:style="style"
		style="width: min-content"
		class="showInvalidTooltip"
	>
		<div
			v-if="required"
			class="lba-messages invalid-field-tooltip"
			:class="{ invalidFieldTooltip: (required && !validInterval) }"
			style="left: 5px; top: 65px;"
		>
			{{ $t('validation.schemaRequired', {attribute: 'Interval'})}}
		</div>
		<div
			v-if="requiredRecommended"
			class="lba-messages warning invalid-field-tooltip"
			:class="{ invalidFieldTooltip: (requiredRecommended && !validInterval) }"
			style="left: 5px; top: 65px;"
		>
			{{ $t('validation.requiredRecommended', {attribute: 'Interval'})}}
		</div>
		<small style="margin-left: 5px">
			{{ label }}
			<span v-if="(showMode && !single)" class="interval-showMode showInvalidTooltip">
				{{ `(${this.mode})` }}
			</span>
			<span v-if="required" class="required-sign"></span>
			<slot name="actions" :customValue="this"></slot>
		</small>
		<div name="body" class="interval-body" :class="{ fieldIsInvalid: (required && !validInterval) }">
			<fake-provider
				name="days"
				:rules="validationRules"
				:data-required-recommended="requiredRecommended"
			>
				<input
					v-if="mode.toUpperCase().includes('D')"
					name="days"
					type="number"
					ref="days"
					v-model="attribute.days"
					class="interval-item"
					style="width: 52px;"
					:disabled="readOnly"
					@input="parseNumber('days'); $emit('input', attribute); inputDirty = true"
					@change="parseNumber('days'); $emit('change', attribute); inputDirty = true"
					@focus="$refs.days.select()"
					:step="hourStep"
					placeholder="00"
				>
			</fake-provider>
			<span
				v-if="(mode.toUpperCase().includes('D') && mode.toUpperCase().includes('H'))"
				class="interval-colon"
			>
				:
			</span>
			<fake-provider
				name="hours"
				:rules="validationRules"
				:data-required-recommended="requiredRecommended"
			>
				<input
					v-if="mode.toUpperCase().includes('H')"
					name="hours"
					type="number"
					ref="hours"
					v-model="attribute.hours"
					class="interval-item"
					style="width: 52px;"
					:disabled="readOnly"
					@input="parseNumber('hours'); $emit('input', attribute); inputDirty = true"
					@change="parseNumber('hours'); $emit('change', attribute); inputDirty = true"
					@focus="$refs.hours.select()"
					:step="hourStep"
					placeholder="00"
				>
			</fake-provider>
			<span
				v-if="(mode.toUpperCase().includes('H') && mode.toUpperCase().includes('M'))"
				class="interval-colon"
			>
				:
			</span>
			<fake-provider
				name="minutes"
				:rules="validationRules"
				:data-required-recommended="requiredRecommended"
			>
				<input
					v-if="mode.toUpperCase().includes('M')"
					name="minutes"
					type="number"
					ref="minutes"
					v-model="attribute.minutes"
					class="interval-item"
					style="width: 50px;"
					:disabled="readOnly"
					@input="parseNumber('minutes'); $emit('input', attribute); inputDirty = true"
					@change="parseNumber('minutes'); $emit('change', attribute); inputDirty = true"
					@focus="$refs.minutes.select()"
					:step="minuteStep"
					placeholder="00"
				>
			</fake-provider>
			<span
				v-if="(mode.toUpperCase().includes('M') && mode.toUpperCase().includes('S'))"
				class="interval-colon"
			>
				:
			</span>
			<fake-provider
				name="seconds"
				:rules="validationRules"
				immediate
				:data-required-recommended="requiredRecommended"
			>
				<input
					v-if="mode.toUpperCase().includes('S')"
					name="seconds"
					type="number"
					ref="seconds"
					v-model="attribute.seconds"
					class="interval-item"
					style="width: 50px;"
					:disabled="readOnly"
					@input="parseNumber('seconds'); $emit('input', attribute); inputDirty = true"
					@change="parseNumber('seconds'); $emit('change', attribute); inputDirty = true"
					@focus="$refs.seconds.select()"
					:step="secondStep"
					placeholder="00"
				>
			</fake-provider>
			<span v-if="single" style="position: relative; left: -15px; margin-bottom: 0;">
				{{ this.mode.slice(-1).toLowerCase() }}
			</span>
			<i class="icon-clock interval-icon"></i>
		</div>
	</s>
</template>
<script>
import CustomInputMixin from './mixins/CustomInput';

export default {
	name: 'InputInterval',
	mixins: [CustomInputMixin],
	props: {
		attribute: {
			type: Object,
			required: true,
		},
		noInput: {
			type: Boolean,
			default: false,
		},
		dirty: Boolean,
		rootTag: {
			type: String,
			default: 'span',
		},
		mode: {
			type: String,
			default: 'HH:MM',
		},
		single: {
			type: Boolean,
			default: false,
		},
		label: {
			type: String,
			default: 'Interval',
		},
		showMode: {
			type: Boolean,
			default: false,
		},
		hourStep: {
			type: Number,
			default: 1,
		},
		minuteStep: {
			type: Number,
			default: 1,
		},
		secondStep: {
			type: Number,
			default: 1,
		},
		required: {
			type: Boolean,
			default: false,
		},
		requiredRecommended: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputDirty: false,
			dataLabels: ['days', 'hours', 'minutes', 'seconds'],
			noInputText: '',
			rules: {},
			options: null,
		};
	},
	computed: {
		validationRules() {
			let rules = {};
			if (this.required) {
				rules = { requiredInterval: true };
				rules.requiredInterval = { time: this.attribute };
			}
			return rules;
		},
		validInterval() {
			if (
				this.attribute.hours === '00' &&
				this.attribute.minutes === '00' &&
				this.attribute.seconds === '00' &&
				this.attribute.days === '00' &&
				this.inputDirty
			) {
				return false;
			} else if (!this.inputDirty) {
				return true;
			}
			return true;
		},
	},
	watch: {
		attribute: {
			deep: true,
			handler() {
				if (this.attribute.days >= 999) { this.attribute.days = '999'; }
				if (this.attribute.hours >= 999 && !this.attribute.days) {
					this.attribute.hours = '999';
				}
				if (this.attribute.hours >= 24 && this.attribute.days) {
					this.attribute.hours = '00';
					this.handleTimeOverflow('days', 'increment');
				}
				if (this.attribute.seconds >= 60) {
					this.attribute.seconds = '00';
					this.handleTimeOverflow('minutes', 'increment');
				}
				if (this.attribute.minutes >= 60) {
					this.attribute.minutes = '00';
					this.handleTimeOverflow('hours', 'increment');
				}
				if (
					(this.attribute.seconds < 0) && ((this.attribute.minutes > 0) ||
					(this.attribute.hours > 0) ||
					(this.attribute.days > 0))
				) {
					this.attribute.seconds = '59';
					this.handleTimeOverflow('minutes', 'decrement');
				} else if (this.attribute.seconds < 0) {
					this.attribute.seconds = '00';
				}
				if (
					this.attribute.minutes < 0 &&
					(this.attribute.hours > 0 || this.attribute.days > 0)
				) {
					this.attribute.minutes = '59';
					this.handleTimeOverflow('hours', 'decrement');
				} else if (this.attribute.minutes < 0) {
					this.attribute.minutes = '00';
				}
				if (this.attribute.hours < 0 && this.attribute.days) {
					this.attribute.hours = '23';
					if (this.attribute.days > 0) { this.handleTimeOverflow('days', 'decrement'); }
				}
				if (this.attribute.hours < 0 && !this.attribute.days) { this.attribute.hours = '00'; }
				if (this.attribute.days < 0) { this.attribute.days = '00'; }
			},
		},
	},
	async created() {
		await this.prepareData();
	},
	methods: {
		parseNumber(key) {
			this.attribute[key] = parseInt(this.attribute[key], 10);
			if (!isNaN(this.attribute[key])) {
				this.attribute[key] = this.leadingZero(key);
			}
			if (!this.attribute[key] || isNaN(this.attribute[key])) {
				this.attribute[key] = '00';
			}
		},
		leadingZero(key) {
			if (this.attribute[key].toString().length === 1) {
				return `0${this.attribute[key]}`;
			} else {
				return `${this.attribute[key]}`;
			}
		},
		handleTimeOverflow(key, action) {
			if (action === 'increment') {
				this.attribute[key] = parseInt(this.attribute[key]) + 1;
				this.parseNumber(key);
			} else if (action === 'decrement') {
				this.attribute[key] = parseInt(this.attribute[key]) - 1;
				this.parseNumber(key);
			}
		},
		checkMode(dataLabel) {
			return this.mode.toLowerCase().includes(dataLabel[0].toLowerCase());
		},
		prepareData() {
			for (let i = 0; i < this.dataLabels.length; i++) {
				if (!this.attribute[this.dataLabels[i]] && this.checkMode(this.dataLabels[i])) {
					this.$set(this.attribute, `${this.dataLabels[i]}`, '00');
					this.attribute[this.dataLabels[i]] = '00';
				} else if (this.checkMode(this.dataLabels[i])) {
					this.parseNumber(this.dataLabels[i]);
				}
			}
		},
		noInputData() {
			const timeData = this.attribute;
			const timeString = `${timeData.days}d ${timeData.hours}h ${timeData.minutes}m ${timeData.seconds}s`;
			return timeString;
		},
	},
};
</script>
