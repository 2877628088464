<template>
	<div id="content" ref="content" class="search-page" v-if="loaded">
		<div class="page-header">
			<h1>
				{{ $t("search") }}<template v-if="!_.isEmpty(searchText)">&colon;<span>&nbsp;{{ searchText }}</span></template>
			</h1>
			<div class="page-controls">
			</div>
		</div>
		<div
			class="page-content"
			@scroll="setScroll"
		>
			<div class="row mb-2" style="column-gap: 40px; row-gap: 20px;">
				<div class="col-4" style="flex-shrink: 0; padding-top: 22px; min-width: 300px;">
					<div class="displayFlex">
						<span class="input-icon">
							<i class="icon-search"></i>
							<input
								type="text"
								v-model="searchText"
								@input="onSearchTextChange()"
								:placeholder="$t('insertSearchText')"
							/>
						</span>
						<button
							type="button"
							class="button mr-2"
							@click="$root.$emit('lbadmin.fulltext-force-search')"
							:disabled="searchText === '' || isSearching"
						>
							{{ $t('fulltext.search') }}
						</button>
					</div>
				</div>
				<div class="col-7">
					<s class="wide">
						<small>{{ $t('fulltext.include') }}</small>
						<label v-for="(value, key) in targetSearch" class="checkbox mr-3" :key="`fulltext-target-${key}`">
							<input
								type="checkbox"
								v-model="targetSearch[key]"
								@change="onTargetSearchChange()"
							>
							<i class="icon-ok"></i>
							<span class="label">{{ $t(`fulltext.targetSearch.${key}`) }}</span>
						</label>
					</s>
				</div>
			</div>

			<lba-content-tabs
				v-model="tabs"
				:defaultTab="activeTab"
				containsGrid
				class="full-tabs"
				@active-tab-changed="activeTabChanged"
			>
				<template v-slot:default="tabProps">
					<div v-for="tab in tabs" :key="`tab-${tab.id}`">
						<lba-fulltext-search-content-tab
							v-if="tabProps.activated[tab.id]"
							v-model="searchText"
							:isActive="tab.id === activeTab"
							:docTypes="tab.docTypes"
							:targetSearch="targetSearch"
							:defaultCurrentPerPage="defaultCurrentPerPage"
							@searching="tab.isSearching = $event"
							@input="onSearchTextChange"
						/>
					</div>
				</template>
			</lba-content-tabs>
		</div>
	</div>
</template>

<script>
import Titles from '../mixins/Titles';
import Page from '../mixins/Page';
import OnScroll from '../mixins/OnScroll';
import FulltextModel from '../models/Fulltext.js';

export default {
	name: 'Search',
	mixins: [
		Titles,
		Page,
		OnScroll,
	],
	data() {
		return {
			fulltextModel: null,
			loaded: false,
			meta: {
				name: this.$t('search'),
				path: 'lbadmin-search',
			},
			tabs: [
				{
					id: 'all',
					docTypes: [],
					label: 'Vše',
					visible: true,
					isSearching: false,
				},
			],
			targetSearch: {
				title: true,
				content: true,
				persons: true,
				emails: true,
				numbers: true,
				labels: true,
			},
			activeTab: 'all',
			searchText: '',
			defaultCurrentPerPage: 50,
		};
	},
	computed: {
		isSearching() {
			return $_.find(this.tabs, { id: this.activeTab })?.isSearching;
		},
	},
	watch: {
		$route() {
			if (this.$route.name === 'lbadmin-search') {
				this.parseRouteQuery();
			}
		},
	},
	async created() {
		this.fulltextModel = new FulltextModel(this.$http);

		$_.forEach(this.$user.fulltextGroups, (fulltextGroup) => {
			this.tabs.push({
				id: fulltextGroup.group_name,
				docTypes: fulltextGroup.docTypes,
				label: this.$getLocale(fulltextGroup.name),
				visible: true,
				isSearching: false,
			});
		});

		$_.forEach(this.$user.fulltext, (fulltextSearch) => {
			this.tabs[0].docTypes.push(fulltextSearch.docType);
		});

		this.parseRouteQuery();

		const settings = await this.fulltextModel.getSettings();
		this.defaultCurrentPerPage = settings.data.per_page || 50;

		this.loaded = true;
	},
	methods: {
		parseRouteQuery() {
			if (!$_.isEmpty(this.$route.query.i)) {
				$_.forEach(this.targetSearch, (value, key) => {
					const isTargetEnabled = this.$route.query.i === 'all' || this.$route.query.i.includes(key);
					if (this.targetSearch[key] !== isTargetEnabled) {
						this.targetSearch[key] = isTargetEnabled;
					}
				});
			}

			if (
				this.$route.query.t !== this.activeTab &&
				!$_.isEmpty(this.$route.query.t) && (
					this.$route.query.t !== 'all' ||
					$_.some(this.tabs, { id: this.$route.query.t })
				)
			) {
				this.activeTab = this.$route.query.t;
			}

			const decodedQuery = decodeURIComponent(this.$route.query.q || '');
			if (decodedQuery !== this.searchText) {
				this.searchText = decodedQuery;
			}
		},
		onTargetSearchChange() {
			const include = [];
			$_.forEach(this.targetSearch, (value, key) => {
				if (value) {
					include.push(key);
				}
			});

			if (include.length === Object.keys(this.targetSearch).length) {
				const query = $_.cloneDeep(this.$route.query);
				query.i = 'all';
				this.$routerWrap.push({ query });
				return;
			}

			const query = $_.cloneDeep(this.$route.query);
			query.i = include;
			this.$routerWrap.push({ query });
		},
		onSearchTextChange() {
			const query = $_.cloneDeep(this.$route.query);
			query.q = encodeURIComponent($_.trim(this.searchText));
			this.$routerWrap.push({ query });
		},
		activeTabChanged(newTab) {
			const query = $_.cloneDeep(this.$route.query);
			query.t = newTab;
			this.$routerWrap.push({ query });
		},
	},
};
</script>
