<template>
	<s
		:data-cy="currentComponentId"
	>
		<div @click.stop="openDialog()" class="link-icon">
			<span>
				{{ $t('timeRange') }}: <i class="icon-clock2"></i>
				<template v-if="range.selectedRange !== ''">{{ ranges[range.selectedRange] }}</template>
				<template v-else>{{ $t('custom') }}</template>
			</span>
		</div>
		<lba-dialog-modal
			:parentComponentId="currentComponentId"
			:name="`lba-input-range-dialog-${this.currentComponentId}`"
			:titleHtml="$t('timeRange')"
			:warningOnEscape="dirty"
		>
			<div class="popup-body pr-1">
				<div class="flex-row pb-3">
					<div class="alignLeft">
						<small class="displayBlock mb-3">{{$t('customRange')}}:</small>
						<s class="mb-3">
							<small>{{$t('from')}}:</small>
							<lba-datepicker
								:parentComponentId="currentComponentId"
								componentId="periodFrom"
								v-model="innerRange.from"
								@change="selectedInnerRange = ''"
								@input="dirty = true; selectedInnerRange = ''"
								:mode="'datetime'"
							></lba-datepicker>
						</s>
						<br>
						<s>
							<small>{{$t('to')}}:</small>
							<lba-datepicker
								:parentComponentId="currentComponentId"
								componentId="periodFrom"
								v-model="innerRange.to"
								v-on:change="selectedInnerRange = ''"
								v-on:input="dirty = true; selectedInnerRange = ''"
								:mode="'datetime'"
								class="w-auto"
							></lba-datepicker>
						</s>
					</div>
					<div class="alignLeft">
						<small class="displayBlock mb-3">{{$t('quickRanges')}}:</small>
						<div class="tag-cloud">
							<div
								v-for="(r, index) in ranges"
								:key="index"
								class="link pr-1"
								v-bind:class="{ strong: selectedInnerRange==index}"
							>
								<span @click.stop="setRange(index)">{{ r }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="info-box mb-3" v-if="diff">
					<i class="icon-circle-warning orange"></i>
					<span v-html="$t('validation.invalidInputRange')"></span>
				</div>
			</div>
			<div class="popup-footer">
				<button
					name="confirm"
					class="button"
					:data-cy="`${currentComponentId}__confirm`"
					@click.stop="confirm"
					:disabled="diff"
				>
					{{ $t('ok') }}
				</button>
				<button
					name="cancel"
					:data-cy="`${currentComponentId}__cancel`"
					@click.stop="$root.$emit('dialog-close', `lba-input-range-dialog-${currentComponentId}`)"
					class="buttonInverse"
				>
					{{ $t('cancel') }}
				</button>
			</div>
		</lba-dialog-modal>
	</s>
</template>
<script>
export default {
	name: 'InputRange',
	model: {
		prop: 'range',
	},
	props: {
		range: {
			type: Object,
		},
		defaultRange: {
			type: String,
		},
		cb: {
			type: Function,
		},
	},
	data() {
		return {
			init: false,
			value: null,
			dirty: false,
			innerRange: {
				from: new Date(),
				to: new Date(),
			},
			ranges: {
				l1hour: this.$t('lastHour'),
				l6hour: this.$t('last6Hours'),
				l12hour: this.$t('last12Hours'),
				l24hour: this.$t('last24Hours'),
				l2day: this.$t('last2Days'),
				l7day: this.$t('last7Days'),
				l30day: this.$t('last30Days'),
				t1day: this.$t('today'),
				t1week: this.$t('thisWeek'),
				t1month: this.$t('thisMonth'),
				t2day: this.$t('yesterday'),
				t3day: this.$t('dayBeforeYesterday'),
				t2week: this.$t('previousWeek'),
				t2month: this.$t('previousMonth'),
			},
			selectedRange: 'l6hour',
			selectedInnerRange: 'l6hour',
			valueLabel: this.$t('customRange'),
			showInputRange: false,
			currentComponentId: null,
		};
	},
	computed: {
		diff() {
			const from = new Date(this.innerRange.from);
			from.setHours(from.getHours() + 1);
			return from > this.innerRange.to;
		},
	},
	watch: {
		value(newValue, oldValue) {
			this.$emit('input', newValue);
		},
	},
	created() {
		this.currentComponentId = this.$generateUID();
		if (!this.value && this.defaultRange) {
			this.innerRange = this.text2range(this.defaultRange);
		} else {
			this.innerRange = Object.assign(this.innerRange, this.value);
		}
		this.init = true;
	},
	methods: {
		openDialog() {
			this.selectedInnerRange = this.range.selectedRange;
			this.innerRange.from = this.range.from;
			this.innerRange.to = this.range.to;
			this.dirty = false;
			this.$root.$emit('dialog-open', { name: `lba-input-range-dialog-${this.currentComponentId}` });
		},
		text2range(text) {
			this.selectedInnerRange = text;
			const to = moment();
			let { toNow, count, type } = (/^(?<toNow>[a-z])(?<count>[0-9]{1,2})(?<type>[a-z]{1,})$/gi.exec(text)).groups;

			if (toNow === 't') {
				count--;
				to.endOf(type);
				if (count > 0) {
					to.subtract(count, type);
				}
				count = 1;
			}
			const from = moment(to).subtract(count, type);

			return {
				from: new Date(from.format('YYYY-MM-DD HH:mm')),
				to: new Date(to.format('YYYY-MM-DD HH:mm')),
			};
		},
		setRange(text) {
			const range = this.text2range(text);
			this.innerRange = range;
			this.confirm();
		},
		reformat() {
			return {
				from: this.innerRange.from,
				to: this.innerRange.to,
				selectedRange: this.selectedInnerRange,
			};
		},
		confirm() {
			this.value = this.reformat();
			this.valueLabel = this.ranges[this.selectedInnerRange] || this.$t('customRange');
			this.selectedRange = this.selectedInnerRange;
			if (this.cb) {
				this.cb(this.value, this.selectedRange);
			}
			this.$root.$emit('dialog-close', `lba-input-range-dialog-${this.currentComponentId}`);
		},
	},
};
</script>
