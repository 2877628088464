<template>
	<div
		v-if="isFulltextAllowed"
		id="search"
		class="header-plugin"
		v-tooltip="$t('search')"
	>
		<span v-lba-expander.closeOnClick ref="expander">
			<i class="icon-search top-1"></i>
		</span>
		<div class="search-dropdown expand">
			<div class="search-form">
				<i class="icon-search"></i>
				<input
					ref="search"
					id="fulltextSearch"
					v-model="fulltextSearch"
					:placeholder="$t('insertSearchText')"
					type="text"
					@input="suggestionDebouncer.emit()"
					@keydown.stop
					@keyup.stop="onKeyUp"
				/>
				<!-- <div v-if="suggestions && suggestions.length" class="search-hints">
					<div
						class="search-hint"
						v-for="(item, index) in suggestions"
						:key="`suggestion-${index}`"
						@click="jumpToSearch(item.term)"
					>
						<i class="icon-clock"></i>
						<i class="icon-three-dots"></i>
						{{ item.term }}
					</div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import ComponentIdentifier from '../mixins/ComponentIdentifier';
import FulltextModel from '../models/Fulltext.js';

export default {
	name: 'LbaFulltextSearch',
	mixins: [ComponentIdentifier],
	data() {
		return {
			fulltextSearch: '',
			suggestionDebouncer: null,
			suggestionsSocket: null,
			searchDelay: 200,
			fulltextInputFocus: false,
			suggestionCallbacks: [],
			suggestions: [
				// DEBUG vysledky naseptavani - pro stylovani
				// { term: 'na', weight: 3 },
				// { term: 'nahrávky', weight: 2 },
				// { term: 'nahrávání', weight: 1 },
			],
			suggestionPromise: null,
			isFetchingSuggestions: false,
			isFulltextAllowed: null,
		};
	},
	async created() {
		this.fulltextModel = new FulltextModel(this.$http);

		this.suggestionDebouncer = new this.$Debouncer(
			this,
			this.getSuggestions.bind(this),
			null,
			this.searchDelay
		);

		this.isFulltextAllowed = this.$root.fulltextEnabled && !$_.isEmpty(this.$user.fulltextGroups);
		await this.$nextTick();

		this.intersectionObserver = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					if (entry.intersectionRatio > 0) {
						this.$refs?.search?.focus();
						this.$refs?.search?.select();
					}
				});
			},
			{
				rootMargin: '0px',
				threshold: 1,
			}
		);
		await this.$nextTick();

		if (this.$refs?.search) {
			this.intersectionObserver.observe(this.$refs.search);
		}
	},
	beforeDestroy() {
		if (this.intersectionObserver && this.$refs?.search) {
			this.intersectionObserver.unobserve(this.$refs.search);
		}
	},
	methods: {
		onKeyUp(event) {
			if (event.key === 'Enter') {
				if (!this.isFetchingSuggestions) {
					this.suggestionDebouncer.forceTimeoutHandler();
				}
				this.search();
			}
		},
		async getSuggestions() {
			if (this.isFetchingSuggestions) {
				this.suggestionDebouncer.emit();
				return;
			}

			this.isFetchingSuggestions = true;
			if ($_.isEmpty($_.trim(this.fulltextSearch))) {
				this.suggestionPromise = Promise.resolve();
				this.suggestions = [];
			} else {
				let resolvePromise = null;
				this.suggestionPromise = new Promise((resolve) => {
					resolvePromise = resolve;
				});

				let response = null;
				try {
					response = await this.fulltextModel.getSuggestions($_.trim(this.fulltextSearch));
				} catch (error) {
					console.error(error);
				}

				if (!$_.isEmpty(response?.data)) {
					this.suggestions = response.data;
				} else {
					this.suggestions = [];
				}
				resolvePromise();
			}
			this.isFetchingSuggestions = false;
		},
		async search() {
			if ($_.isEmpty($_.trim(this.fulltextSearch))) {
				return;
			}

			await this.suggestionPromise;

			this.$refs?.expander?.setAttribute('data-close-expander', 'true');

			if (
				this.suggestions.length === 1 &&
				this.suggestions[0].suggestionType === 'custom' &&
				$_.trim(this.fulltextSearch).startsWith('#')
			) {
				this.$routerWrap.push({
					name: this.suggestions[0].routeName,
					params: this.suggestions[0].routeParams,
				});
				return;
			}

			this.$routerWrap.push({
				name: 'lbadmin-search',
				params: {
					openNewTab: true,
				},
				query: {
					q: encodeURIComponent($_.trim(this.fulltextSearch)), // query
					t: 'all', // content tab,
					i: 'all', // search in columns
				},
			});
		},
	},
};
</script>
